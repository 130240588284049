import React,  { useContext } from 'react';

import * as style from 'styles/components/account/accountProfile.module.scss';

import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from '../../utils/FormattedMessage';

const ConfirmDeleteAccountModal = ({ onClose, onDeleteAccount }) => {
    const { lang } = useContext(LangContext);
    return (
        <div className={style.profileForm__deleteAccountContent}>
            <p className={style.profileForm__deleteAccountContent__description}>
                <FormattedMessage id="account.deleteAccountDesc" />
            </p>
            <p className={style.profileForm__deleteAccountContent__question}>
                <FormattedMessage id="account.deleteAccountQuestion" />
            </p>
            <div className={style.profileForm__deleteAccountContent__buttonsContainer}>
                <button
                    onClick={onDeleteAccount}
                    type="button"
                    className="cta-yellow"
                    aria-label={getFormattedMessage('account.deleteAccountYes', lang)}
                >
                    <FormattedMessage id="account.deleteAccountYes" />
                </button>
                <button
                    onClick={onClose}
                    className="cta-white"
                    type="button"
                    aria-label={getFormattedMessage('account.deleteAccountNo', lang)}
                >
                    <FormattedMessage id="account.deleteAccountNo" />
                </button>
            </div>
        </div>
    );
};

export default ConfirmDeleteAccountModal;
